import React, { ReactNode } from 'react';
import { ChakraProvider, cookieStorageManagerSSR, localStorageManager } from '@chakra-ui/react';
import theme from '../../theme';
import Footer from '../Footer';
import { GetServerSideProps } from 'next';
import { AdminContainer } from './container';

interface LayoutProps {
	children: ReactNode;
	cookies?: string;
}

const Layout: React.FC<LayoutProps> = ({ children, cookies }) => {
	const manager = typeof cookies === 'string' ? cookieStorageManagerSSR(cookies) : localStorageManager;

	return (
		<>
			<ChakraProvider theme={theme} colorModeManager={manager}>
				<AdminContainer>
					{children}
					<Footer />
				</AdminContainer>
			</ChakraProvider>
		</>
	);
};

export const getServerSideProps: GetServerSideProps = async ({ req }) => {
	return {
		props: {
			// first time users will not have any cookies and you may not return
			// undefined here, hence ?? is necessary
			cookies: req.headers.cookie ?? '',
		},
	};
};

export default Layout;
