import { mode, StyleFunctionProps } from '@chakra-ui/theme-tools';

export const ModalStyles = {
	// style object for base or default style
	baseStyle: {},
	// styles for different sizes ("sm", "md", "lg")
	sizes: {},
	// styles for different visual variants ("outline", "solid")
	variants: {
		landing: (props: StyleFunctionProps) => ({
			dialog: {
				backgroundColor: mode('gray.400', '#303030')(props),
				borderRadius: '5px',
			},
		}),
	},
	// default values for `size` and `variant`
	defaultProps: {
		size: '',
		variant: '',
	},
};
