import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { UserInfo } from '../types';

export interface ArkContextType {
	isLoggedIn: boolean;
	setIsLoggedIn: (state: boolean) => void;
	login: (emailAddress: string, passWord: string) => Promise<void>;
	logout: () => void;
	currentUser: UserInfo | undefined;
	axios: AxiosInstance;
}

const ArkContext = createContext<ArkContextType>({} as ArkContextType);

export const ArkProvider: React.FC = ({ children }: { children?: ReactNode }) => {
	const [initialised, setInitialised] = useState<boolean>(false);
	const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
	const [currentUser, setCurrentUser] = useState<UserInfo>();
	const [accessToken, setAccessToken] = useState<string | undefined>();

	const authAxios = axios.create();
	authAxios.interceptors.request.use(
		(config: AxiosRequestConfig) => {
			if (accessToken) {
				config!.headers!.Authorization = `Bearer ${accessToken}`;
			}
			return config;
		},
		(err) => {
			return Promise.reject(err);
		}
	);

	useEffect(() => {
		init();
	}, []);

	const init = async () => {
		const accessToken = sessionStorage.getItem('accessToken');
		const currentUser = sessionStorage.getItem('userDetails');

		if (currentUser && accessToken) {
			try {
				setIsLoggedIn(true);
				setAccessToken(accessToken);
				setCurrentUser(JSON.parse(currentUser));
			} catch (err) {
				setIsLoggedIn(false);
				sessionStorage.clear();
			}
		}
		setInitialised(true);
	};

	const login = async (email: string, password: string) => {
		try {
			const { data } = await axios.post('/api/login', {
				email,
				password,
			});
			if (data) {
				setIsLoggedIn(true);
				setAccessToken(data.metadata.accessToken);
				setCurrentUser(data.metadata.userInfo);
				sessionStorage.setItem('accessToken', data.metadata.accessToken);
				sessionStorage.setItem('userDetails', JSON.stringify(data.metadata.userInfo));
			}
		} catch (err) {
			throw err;
		}
	};

	const logout = () => {
		sessionStorage.removeItem('accessToken');
		sessionStorage.removeItem('userDetails');
		setCurrentUser(undefined);
		setAccessToken(undefined);
		setIsLoggedIn(false);
	};

	return (
		<ArkContext.Provider
			value={{
				isLoggedIn,
				setIsLoggedIn,
				login,
				logout,
				currentUser,
				axios: authAxios,
			}}
		>
			{initialised ? children : undefined}
		</ArkContext.Provider>
	);
};

export const useArkContext = () => {
	return useContext(ArkContext);
};

export default useArkContext;
