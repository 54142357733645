import '../styles/globals.css';
import '../src/theme/styles.css';

import { AppProps } from 'next/app';
import Layout from '../src/components/Layout';
import NextNProgress from 'nextjs-progressbar';
import { ArkProvider } from '../src/provider/ArkProvider';
import { ConfigProvider } from '../src/provider/ConfigProvider';
import Script from 'next/script';

interface ArkProps extends AppProps {
	userToken?: string;
}

const MyApp = ({ Component, pageProps }: ArkProps) => {
	return (
		<>
			<Script src="https://accounts.google.com/gsi/client" async defer />
			<ArkProvider>
				<ConfigProvider>
					<NextNProgress height={6} color={`linear-gradient(0deg, rgba(250, 232, 70, 1) 0%, rgba(71, 139, 162, 1) 100%)`} />
					<Layout>
						<Component {...pageProps} />
					</Layout>
				</ConfigProvider>
			</ArkProvider>
		</>
	);
};

export default MyApp;
