// Config State
export type ConfigState = {
	siteConfig: ISiteConfig[];
};

export enum ConfigKey {
	SITE_DEFAULT_HEADER = 'site_default_header',
	SITE_FOOTER_DARK = 'site_footer_dark',
	SITE_FOOTER_LIGHT = 'site_footer_light',
	SITE_LANDING_BANNER = 'site_landing_banner',
	SITE_LOGO_DARK = 'site_logo_dark',
	SITE_LOGO_LIGHT = 'site_logo_light',
	SITE_ROYALTY = 'site_royalty',
	SITE_DESC = 'site_description',
	SITE_NAME = 'site_name',
	SITE_TITLE = 'site_title',
}

export interface ISiteConfig {
	ADMIN_ONLY: number;
	NAME: ConfigKey;
	VALUE: string;
}

export type Nullable<T> = T | null;

export interface ApiResponse<T> {
	status: string;
	metadata: T;
}

export interface UserInfo {
	email: string;
	first_name: string;
	last_name: string;
}

export interface Admin {
	first_name: string;
	last_name: string;
	email: string;
}

export interface RegisterAdmin {
	firstName: string;
	lastName: string;
	email: string;
	password: string;
}

export interface Category {
	description?: string;
	id: number;
	name: string;
	status: string;
}

export enum UserType {
	CREATOR = 1,
	USER = 0,
}

export enum ApplicationStatus {
	APPROVED = 1,
	DENIED = 2,
	PENDING = 0,
}

export interface Application {
	APPLICATION_FOR_CREATOR: number;
	AVATAR: string;
	CITY: string;
	COUNTRY: string;
	COVER: string;
	DESCRIPTION: string;
	EMAIL: string;
	ETH_ADDRESS: string;
	LAST_NAME: string;
	NAME: string;
	PHONE_NUMBER: string;
	USERNAME: string;
	USER_TYPE: UserType;
	description: string;
	id: number;
	sample: string | null;
	status: ApplicationStatus;
	userID: number;
}

export interface AccordionAction {
	label?: string;
	icon?: JSX.Element;
	onClick: () => void;
}

export interface DetailAccordionProps {
	actions: AccordionAction[];
	headerContent: JSX.Element;
	bodyContent: JSX.Element;
}

export interface Collection {
	categoryID: number;
	cover: string;
	created_at: Date;
	description: string;
	discord: string | null;
	icon: string;
	id: number;
	instagram: string | null;
	name: string;
	site_royalty: number | null;
	tiktok: string | null;
	twitter: string | null;
	updated_at: Date;
	userID: number;
	website: string | null;
}

export enum MintStatus {
	PENDING = 0,
	MINTED = 1,
}

export enum NftStatus {
	ACTIVE = 1,
	INACTIVE = 0,
}

export interface NFT {
	animation_url: string | null;
	animation_url_mime_type: string | null;
	collectionID: string;
	created_at: Date;
	creator: string;
	description: string;
	download_url: string | null;
	dropNFT: number;
	id: number;
	image_url: string | null;
	image_url_s3: string | null;
	status: NftStatus;
	minted: MintStatus;
	name: string;
	tags: string;
	updated_at: Date;
}

export enum UserStatus {
	BANNED = 0,
	ACTIVE = 1,
}

export interface User {
	APPLICATION_FOR_CREATOR: ApplicationStatus;
	APP_KEY: string | null;
	AVATAR: string | null;
	BROWSER_KEY: string | null;
	CITY: string | null;
	COUNTRY: string | null;
	COVER: string | null;
	CREATED_AT: Date;
	DESCRIPTION: string | null;
	EMAIL: string | null;
	ETH_ADDRESS: string;
	ID: number;
	LAST_NAME: string | null;
	NAME: string | null;
	NONCE: string | null;
	PHONE_NUMBER: string | null;
	STATUS: UserStatus;
	UPDATED_AT: Date;
	USERNAME: string | null;
	USER_TYPE: UserType;
}

export interface BaseFilter {
	page?: number;
	perPage?: number;
	keyword?: string;
}

export interface ApplicationFilters extends BaseFilter {
	applicationID?: number;
	status?: ApplicationStatus;
	userID?: number;
}

export interface NFTFilters extends BaseFilter {
	collectionID?: number;
	ignoredIDs?: number;
	tokenID?: number;
	status?: MintStatus;
}

export interface CollectionFilters extends BaseFilter {
	categoryID?: number;
	userID?: number;
}

export interface UserFilters extends BaseFilter {
	walletAddress?: string;
	emailAddress?: string;
	userType?: UserType;
}
