import React, { FC, ReactNode } from 'react';
import {
	Avatar,
	AvatarGroup,
	Box,
	BoxProps,
	CloseButton,
	Divider,
	Drawer,
	DrawerContent,
	Flex,
	FlexProps,
	HStack,
	Icon,
	IconButton,
	Menu,
	MenuButton,
	MenuDivider,
	MenuItem,
	MenuList,
	Text,
	useColorModeValue,
	useDisclosure,
	VStack,
} from '@chakra-ui/react';
import { FiBell, FiBriefcase, FiChevronDown, FiCompass, FiHome, FiMenu, FiSettings, FiStar, FiUserCheck, FiUsers } from 'react-icons/fi';
import { IconType } from 'react-icons';
import { useRouter } from 'next/router';
import { BiAddToQueue, BiListUl, BiSprayCan } from 'react-icons/bi';
import useArkContext from '../../provider/ArkProvider';
import FallbackLogo from '../../assets/images/logo.svg';
import Image from 'next/image';
import { StaticImport } from 'next/dist/shared/lib/get-img-props';

interface LinkItemProps {
	name: string;
	icon: IconType;
	children?: Array<LinkItemProps>;
	path?: string;
}
const LinkItems: Array<LinkItemProps> = [
	{ name: 'Home', icon: FiHome, path: '/dashboard' },
	{ name: 'Application', icon: FiCompass, path: '/applications' },
	{
		name: 'Categories',
		icon: FiBriefcase,
		children: [
			{ name: 'List', icon: BiAddToQueue, path: '/category/list' },
			{ name: 'Add', icon: BiListUl, path: '/category/add' },
		],
	},
	{
		name: 'Collection',
		icon: FiStar,
		path: '/collections',
	},
	{
		name: 'NFTs',
		icon: BiSprayCan,
		path: '/nft',
	},
	{
		name: 'Users',
		icon: FiUsers,
		path: '/users',
	},
	{
		name: 'Super Users',
		icon: FiUserCheck,
		children: [
			{ name: 'List', icon: BiAddToQueue, path: '/admin/list' },
			{ name: 'Add', icon: BiListUl, path: '/admin/add' },
		],
	},
	{ name: 'Settings', icon: FiSettings, path: '/settings' },
];

export const AdminContainer: FC<{ children: ReactNode }> = ({ children }) => {
	const { isLoggedIn: isLogged } = useArkContext();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const router = useRouter();

	const navigateHandler = (action: string) => {
		router.push(action);
	};

	return (
		<Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')}>
			<SidebarContent
				logo={FallbackLogo}
				navigateHandler={navigateHandler}
				isLogged={isLogged}
				onClose={() => onClose}
				display={{ base: 'none', md: 'block' }}
			/>
			<Drawer
				autoFocus={false}
				isOpen={isOpen}
				placement="left"
				onClose={onClose}
				returnFocusOnClose={false}
				onOverlayClick={onClose}
				size="full"
			>
				<DrawerContent>
					<SidebarContent logo={FallbackLogo} onClose={onClose} navigateHandler={navigateHandler} isLogged={isLogged} />
				</DrawerContent>
			</Drawer>
			{/* mobilenav */}
			<MobileNav onOpen={onOpen} logo={FallbackLogo} navigateHandler={navigateHandler} isLogged={isLogged} />
			<Box ml={{ base: 0, md: 60 }} minH="100vh" p="4" bg="black">
				{children}
			</Box>
		</Box>
	);
};

interface SidebarProps extends BoxProps {
	logo: StaticImport;
	onClose: () => void;
	navigateHandler: (action: string) => void;
	isLogged: boolean;
}

const SidebarContent: FC<SidebarProps> = ({ logo, onClose, navigateHandler, isLogged, ...rest }) => {
	return (
		<Box
			transition="3s ease"
			bg={useColorModeValue('white', 'black')}
			borderRight="1px"
			borderRightColor={useColorModeValue('gray.200', 'gray.700')}
			w={{ base: 'full', md: 'max-content' }}
			pos="fixed"
			h="full"
			{...rest}
		>
			<Flex alignItems="center">
				<Image onClick={() => navigateHandler('/')} alt="logo" src={logo} width={140} height={140} />
				<CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
			</Flex>
			{isLogged &&
				LinkItems.map((link) => (
					<NavItem key={link.name} name={link.name} icon={link.icon} navigateHandler={navigateHandler} path={link.path}>
						{link.children &&
							link.children.map((child) => (
								<NavItem
									key={child.name}
									name={child.name}
									icon={child.icon}
									path={child.path}
									navigateHandler={navigateHandler}
									isChild
								/>
							))}
					</NavItem>
				))}
		</Box>
	);
};

interface NavItemProps extends FlexProps {
	name: string;
	icon: IconType;
	children?: ReactNode;
	path?: string;
	isChild?: boolean;
	navigateHandler: (action: string) => void;
}
const NavItem: FC<NavItemProps> = ({ children, icon, name, path, isChild, ...rest }) => {
	const { isOpen, onToggle } = useDisclosure();
	const isParent = children ? true : false;
	const router = useRouter();

	const handleClick = () => {
		if (isParent) onToggle();
		else router.push(path ?? '');
	};

	return (
		<>
			<Box style={{ textDecoration: 'none' }} _focus={{ boxShadow: 'none' }} onClick={handleClick}>
				<Flex
					align="center"
					p={isChild ? '2' : '4'}
					mx={isChild ? '6' : '2'}
					borderRadius="lg"
					role="group"
					cursor="pointer"
					_hover={{
						bgGradient: 'linear-gradient(29deg, #bcc866 0%, #689c90)',
					}}
					{...rest}
				>
					{icon && (
						<Icon
							mr="4"
							fontSize="16"
							_groupHover={{
								color: 'white',
							}}
							as={icon}
						/>
					)}
					<Text>{name}</Text>
				</Flex>
			</Box>
			{isOpen && children}
			{!isChild && <Divider />}
		</>
	);
};

interface MobileProps extends FlexProps {
	onOpen: () => void;
	logo: string | StaticImport;
	navigateHandler: (action: string) => void;
	isLogged: boolean;
}
const MobileNav: FC<MobileProps> = ({ onOpen, logo, navigateHandler, isLogged, ...rest }) => {
	const { currentUser, logout } = useArkContext();
	const router = useRouter();

	const onLogout = () => {
		logout();
		navigateHandler('/login');
	};

	if (!isLogged) return null;

	return (
		<Flex
			ml={{ base: 0, md: 60 }}
			px={{ base: 4, md: 4 }}
			height="20"
			alignItems="center"
			bg={'black'}
			borderBottomWidth="1px"
			borderBottomColor={'gray.700'}
			justifyContent={{ base: 'space-between', md: 'flex-end' }}
			{...rest}
		>
			<IconButton
				display={{ base: 'flex', md: 'none' }}
				onClick={onOpen}
				variant="outline"
				aria-label="open menu"
				icon={<FiMenu />}
			/>

			<Image onClick={() => navigateHandler('/')} alt="logo" src={logo} width={90} height={90} />

			<HStack spacing={{ base: '0', md: '6' }}>
				<IconButton size="lg" variant="ghost" aria-label="open menu" icon={<FiBell />} />
				<Flex alignItems={'center'}>
					<Menu>
						<MenuButton py={2} transition="all 0.3s" _focus={{ boxShadow: 'none' }}>
							<HStack>
								<AvatarGroup cursor="pointer">
									<Avatar size="sm" bgGradient="linear(to-r, rgba(250, 232, 70, 1), rgba(71, 139, 162, 1))" />
								</AvatarGroup>
								<VStack display={{ base: 'none', md: 'flex' }} alignItems="flex-start" spacing="1px" ml="2">
									<Text fontSize="sm">{currentUser && `${currentUser.first_name} ${currentUser.last_name}`}</Text>
									<Text fontSize="xs" color="gray.600">
										Admin
									</Text>
								</VStack>
								<Box display={{ base: 'none', md: 'flex' }}>
									<FiChevronDown />
								</Box>
							</HStack>
						</MenuButton>
						<MenuList bg={'gray.900'} borderColor={'gray.700'} zIndex={999}>
							<MenuItem onClick={() => router.push('/account')}>Account</MenuItem>
							<MenuDivider />
							<MenuItem onClick={() => onLogout()}>Sign out</MenuItem>
						</MenuList>
					</Menu>
				</Flex>
			</HStack>
		</Flex>
	);
};
