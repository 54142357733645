import React from 'react';
import { IoLogoFacebook, IoLogoInstagram, IoLogoTwitter } from 'react-icons/io';
import { Box, Container, Flex, Heading, IconButton, Link, Spacer, Text, useColorModeValue } from '@chakra-ui/react';
import { ConfigKey } from '../../types';
import FallbackLogo from '../../assets/images/logo.svg';
import FallbackBanner from '../../assets/images/footer-image.png';
import useConfig from '../../provider/ConfigProvider';
import Image from 'next/image';

export const Footer = () => {
	const { getConfigByKey } = useConfig();
	const footerLogo = getConfigByKey(ConfigKey.SITE_FOOTER_DARK)?.VALUE ?? FallbackBanner.src;

	return (
		<>
			<Container
				as="div"
				mt={20}
				maxW="100%"
				className="footerPage"
				sx={{
					backgroundImage: `url(${footerLogo})`,
					backgroundSize: 'cover',
				}}
			>
				<Box
					borderTop="1px"
					borderTopColor={useColorModeValue('gray.200', 'gray.700')}
					mb="8"
					borderColor="gray.200"
					bgColor="rgba(0, 0, 0, 0.6)"
				>
					<Flex direction={{ base: 'column', md: 'row' }} alignItems="center">
						<Box>
							<Image width={200} height={200} alt="logo" src={FallbackLogo} />
						</Box>
						<Spacer />
						<Box>
							<Heading mb={4} fontSize="18">
								Legal
							</Heading>
							<ul>
								<li>
									<Link href="/"> License </Link>
								</li>
								<li>
									<Link href="/"> Terms </Link>
								</li>
								<li>
									<Link href="/"> Privacy </Link>
								</li>
							</ul>
						</Box>
						<Spacer />
					</Flex>
					<Flex mx={10} direction={{ base: 'column-reverse', md: 'column' }} alignItems="center" gap={2}>
						<Box className="copyRight">
							<Text>© 2024 {name} Inc. All rights reserved.</Text>
						</Box>
						<Spacer />
						<Box>
							<IconButton aria-label="instagram" className="insta" bg="#f5f6f7" isRound={true} mr="4">
								<IoLogoInstagram />
							</IconButton>
							<IconButton aria-label="facebook" className="fb" bg="#f5f6f7" isRound={true} mr="4">
								<IoLogoFacebook />
							</IconButton>
							<IconButton aria-label="twitter" className="tw" bg="#f5f6f7" isRound={true}>
								<IoLogoTwitter />
							</IconButton>
						</Box>
					</Flex>
				</Box>
			</Container>
		</>
	);
};

export default Footer;
