import { createContext, FC, ReactNode, useContext, useEffect, useState } from 'react';
import { ConfigKey, ISiteConfig, Nullable } from '../types';
import useArkContext from './ArkProvider';

export interface ConfigContextType {
	config: ISiteConfig[];
	getConfigByKey: (key: ConfigKey) => Nullable<ISiteConfig>;
	refreshConfig: () => Promise<void>;
}

const ConfigContext = createContext<ConfigContextType>({} as ConfigContextType);

export const ConfigProvider: FC = ({ children }: { children?: ReactNode }) => {
	const { isLoggedIn, axios } = useArkContext();
	const [configData, setConfigData] = useState<ISiteConfig[]>([]);

	useEffect(() => {
		getConfig();
	}, [isLoggedIn]);

	const getConfig = async () => {
		if (isLoggedIn) {
			const { data } = await axios.get('/api/config');
			setConfigData(data.metadata);
		}
	};

	const getConfigByKey = (key: ConfigKey): Nullable<ISiteConfig> => {
		return configData.filter((item) => item.NAME === key)[0];
	};

	return (
		<ConfigContext.Provider
			value={{
				config: configData,
				getConfigByKey,
				refreshConfig: getConfig,
			}}
		>
			{children}
		</ConfigContext.Provider>
	);
};

export const useConfig = () => {
	return useContext(ConfigContext);
};

export default useConfig;
